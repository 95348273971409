<template>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    css-classes="line-chart"
  />
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import externalTooltipHandler from '@/helpers/tooltip'
import {
  Chart as ChartJS,
  Tooltip,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from 'chart.js'

ChartJS.register(
  Tooltip,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)

class Curve{
  label=''
  data=[]
  borderColor=''
  constructor(label,color,number){
    this.data=new Array(number)
    this.borderColor=color;
    this.label=label
  }

  push(val){
    this.data.shift()
    this.data.push(val)
  }
  
}

class Chart{
  labels=[]
  datasets=[]
  constructor(numbers,arrayCurves=[]){
    this.labels=new Array(numbers)
    this.datasets=arrayCurves
  }

  addCurve(curve){
    this.curves.push(curve)
  }

  push(val){
    this.labels.shift()
    this.labels.push(val)
  }

}

export default {
  name: 'LineChart',
  components: {
    LineChartGenerator
  },
  props: {
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    cssClasses: {
      default: '',
      type: String
    },
    value:{
      type:Object
    },
    numbers:{
      type:Number,
      default:10
    }
  },
  data() {
    
    return {
      chartId:this._uid.toString(),
      colors:['red','blue'],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        animation:false,
        elements:{
          point:{
            radius:1
          }
        },
        interaction: {
          mode: 'index',
          intersect: false,
        },
        plugins: {
          tooltip: {
            enabled: false,
            external: externalTooltipHandler
          }
        },
        scales: {
          x: {
            ticks: {
              maxTicksLimit: 3,
            }
          },
          y: {
            min:0,
            max:100,
            ticks: {
              maxTicksLimit: 5,
            }
          },  
        }
      },
      char:new Chart(this.numbers)
    }
  },
  computed:{
      chartData (){
        return this.char
      },
  },
  methods:{
    update(x,arrayCurve){
      this.char.push(x)
      
      arrayCurve.map((curve)=>{
        if(this.char.datasets.length==0 || !this.char.datasets.find((item)=>item.label==curve.name)){
          const indexColor=Math.floor(Math.random() * this.colors.length)
          this.char.datasets.push(new Curve(curve.name,this.colors[indexColor],this.numbers))
          this.colors.splice(indexColor,1)
        }

        let foundCurve=this.char.datasets.find((item)=>item.label==curve.name)
        foundCurve.push(curve.y)

      })
    }
  },
}
</script>

<style lang="stylus" scoped>
.line-chart
  background white
  padding 25px
  width 100%
  height 300px
  font-weight: 600;
  font-size: 12px;
</style>
