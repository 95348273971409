const freqUpdateInMs = 1000

export default {
  data() {
    return {
      loading: false,
      items: {},
      timer: null
    }
  },
  mounted() {
    this.$store.commit('setLoading', true)
    this.fetchData()
      .then(() => {
        this.timer = setInterval(this.fetchData.bind(this), freqUpdateInMs)
      })
      .finally(() => {
          this.$store.commit('setLoading', false)
      })
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
