<template>
  <div id="app" class="app">
    <div v-if="!loading" class="app__container">
      <router-view/>
      <transition-group class="snackbars" name='list' tag="div">
        <base-snackbar @close="hideSnackbar(item.id)"   v-for="item in getSnackbars" :key="item.id" :type="item.type" :text="item.text"/>
      </transition-group>
    </div>
    <base-spiner v-if='isLoading'/>
  </div>
</template>

<script>
import LocalizationMixin from '@/mixins/LocalizationMixin.js'
import ScrollbarWidth from '@/utils/scrollbar-width'
import DataApi from "@/services/api/DataApi";

export default {
  mixins: [
    LocalizationMixin
  ],
  data() {
    return {
      loading: false
    }
  },
  mounted() {
    this.setScreenSize()
    window.addEventListener('resize', this.setScreenSize)
  },
  computed: {
    getSnackbars() {
      return this.$store.getters['getSnackbars']
    },
    isLoading() {
      return this.$store.getters['getLoading']
    },
  },
  methods:{
    hideSnackbar(id) {
      this.$store.dispatch('hideSnackbar', id)
    },
    fetchLang(languages) {
      for (let key in languages) {
        if (this.$store.getters['localization/getLocaleBrowser'] === new Intl.Locale(key).language) {
          this.$store.dispatch('localization/setLanguage',{  name: languages[key].name, lang: key })
          return
        }
      }
      for (let key in languages) {
        if (key === 'en-EN') {
          this.$store.dispatch('localization/setLanguage',{ name: languages[key].name, lang: key })
          return
        }
      }
    },
    async fetchDeviceInfo() {
      await this.$store.dispatch('frontend/fetchDeviceInfo')
    },
    fetchGui() {
      this.$store.dispatch('frontend/fetchGui')
      this.$store.dispatch('frontend/fetchHiding')
    },
    async fetch() {
      try {
        this.$store.dispatch('frontend/fetchTemplate')
        let data = this.$store.getters['frontend/getTemplate']
        data = data.languages
        this.fetchLang(data)
        this.fetchGui()
        await this.fetchDeviceInfo()
      } catch(e) {
        console.warn(e)
      } finally {
        this.loading = false
      }
      // window.addEventListener('online',this.fetch.bind(this))
    },
    setScreenSize() {
      const width = Math.min(screen.availWidth, window.innerWidth)
      const height = Math.min(screen.availHeight, window.innerHeight)
      const orientation = window.matchMedia('(orientation: portrait)').matches
        ? 'portrait'
        : 'landscape'
      const scrollbar = ScrollbarWidth()
      this.$store.commit('setScreen', { width, height, orientation, scrollbar })
    }
  },
  async created() {
    this.loading = true
    await this.fetch()
  }
}
</script>

<style lang="stylus">
@require '~@/assets/stylus/animation/animations';

setAnimationOnComponent('list',slide-in-bottom,'enter',1s)
setAnimationOnComponent('list',slide-out-bottom,'leave',1s)
</style>

<style lang="stylus" scoped>
@require '~@/assets/stylus/mixins/mixins';
@require '~@/assets/stylus/vars/variables';
.snackbars
  flexy(center,center)
  position absolute
.app
  flexy(center,center)
  width 100%
  &__container
    flexy(center,center)
    width 100%
</style>
