<template>
  <div class="tpl tpl-update tpl_chart">
    <h3 class="tpl__header">{{ translate(title) }}</h3>
    <LineChartGenerator
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      css-classes="line-chart"
    />
  </div>
</template>

<script>
  import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
  import externalTooltipHandler from '@/helpers/tooltip'
  import LocalizationMixin from '@/mixins/LocalizationMixin.js'

  import {
    Chart as ChartJS,
    Tooltip,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement,
    Filler,
  } from 'chart.js'

  ChartJS.register(
    Tooltip,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement,
    Filler,
  )

  class Curve{
    label=''
    data=[]
    borderColor=''
    backgroundColor=''
    pointBackgroundColor=''
    spliceCount=60
    maxTicksLimitX=5
    constructor(label,color,chartData, backgroundColor, spliceCount, maxTicksLimitX){
      let numbers = this.spliceCount * maxTicksLimitX;
      this.data=new Array(numbers)
      this.borderColor=color;
      this.label=label
      this.pointBackgroundColor = color
      this.backgroundColor= backgroundColor
      this.spliceCount= spliceCount
      this.maxTicksLimitX= maxTicksLimitX
    }

    push(val){
      this.data.shift();
      this.data.push(val)
    }

  }

  class Chart{
    labels=[]
    datasets=[]
    maxTicksLimitX = 0
    spliceCount= 60
    constructor(maxTicksLimitX, spliceCount, arrayCurves=[]){
      this.datasets=arrayCurves
      this.maxTicksLimitX = maxTicksLimitX;
      let numbers = this.spliceCount * maxTicksLimitX;
      this.labels=new Array(numbers)
    }

    // Convert time in H[:mm[:ss]] format to seconds
    timeToSecs(time) {
      let [h, m, s] = time.split(':');
      return h*3600 + (m|0)*60 + (s|0)*1;
    }

    // Convert seconds to time in H:mm:ss format
    secsToTime(totalSeconds) {
      const totalMs = totalSeconds * 1000;
      const result = new Date(totalMs).toISOString().slice(11, 19);

      return result;
    }

    getCurrentTime(){
      let now = new Date();
      let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      var options = {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZone: timeZone
      };
      return now.toLocaleString("ru", options)
    }

    pushLabels(labels) {
        let lastEl = labels[labels.length - 1];
        let nextElInSec = this.timeToSecs(lastEl) + 1;
        let nextSec = this.secsToTime(nextElInSec);
        labels.push(nextSec);
    }

    addLabels() {
      let labels = this.labels;
      let maxTicksLimitX = this.maxTicksLimitX;
      let labelsWithoutEmpty = labels.filter(n => n);

      let count = 60 * maxTicksLimitX;

      if (labelsWithoutEmpty.length < count - 1) {
        labels.shift();
        let firstElInSec = this.timeToSecs(this.getCurrentTime()) - count;
        let firstEl = this.secsToTime(firstElInSec);
        labels.push(firstEl);
        while (count > 1) {
          labels.shift();
          this.pushLabels(labels);

          count--
        }
      }
      else {
        labels = labels.map((label) => {
          let nextElInSec = this.timeToSecs(label) + 1;
          let nextSec = this.secsToTime(nextElInSec);
          return nextSec
        });
      }

      this.labels = labels
    }

  }

  export default {
    name: 'AreaChart',
    components: {
      LineChartGenerator
    },
    mixins: [LocalizationMixin],
    props: {
      datasetIdKey: {
        type: String,
        default: 'label'
      },
      cssClasses: {
        default: '',
        type: String
      },
      value: {
        type: Object
      },
      chart: {
        type: Object
      },
      title: {
        type: String
      },

      // количество отображаемых точек на оси X
      maxTicksLimitX: {
        type: Number,
        default: 5
      },
      // количество отображаемых точек на оси Y
      maxTicksLimitY: {
        type: Number,
        default: 10
      },
      displayXLabels: {
        type: Boolean,
        default: false
      }
    },
    data() {

      return {
        chartId: this._uid.toString(),
        colors: ['#FC2525', '#1b6cd0'],
        spliceCount: 60,
        gradients: [],
        gradient1: null,
        gradient2: null,
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          animation: false,
          fill: true,
          elements: {
            point: {
              radius: 1
            }
          },
          interaction: {
            mode: 'index',
            intersect: false,
          },
          plugins: {
            tooltip: {
              enabled: false,
              external: externalTooltipHandler
            }
          },
          scales: {
            x: {
              ticks: {
                min: 0,
                display: this.displayXLabels,
                maxTicksLimit: this.maxTicksLimitX,
              }
            },
            y: {
              min: 0,
              max: 100,
              ticks: {
                maxTicksLimit: this.maxTicksLimitY,
              }
            },
          }
        },
        char: new Chart(this.maxTicksLimitX, this.spliceCount),
      }
    },
    computed: {
      chartData() {
        return this.char
      },
      getCurrentTime(){
        let now = new Date();
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        var options = {
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          timeZone: timeZone
        };
        return now.toLocaleString("ru", options)
      }
    },
    methods: {
      timeToSecs: function(time) {
        let [h, m, s] = time.split(':');
        return h*3600 + (m|0)*60 + (s|0)*1;
      },
      update(x, arrayCurve) {
        let labels = this.char.labels;
        let labelsWithoutEmpty = labels.filter(n => n);
        let count = this.spliceCount * this.maxTicksLimitX
        let checkedEl = labelsWithoutEmpty[labelsWithoutEmpty.length - count];
        let currentDate = this.timeToSecs(x);
        
        if (labelsWithoutEmpty.length === 0 || this.timeToSecs(checkedEl) <= currentDate || currentDate === 0) {
          this.char.addLabels()
        }

        this.updateAfterSleep();

        arrayCurve.map((curve)=>{
          if(this.char.datasets.length==0 || !this.char.datasets.find((item)=>item.label==curve.name)){
            const indexColor=Math.floor(Math.random() * this.colors.length)
            this.char.datasets.push(new Curve(curve.name,this.colors[indexColor],[], this.gradients[indexColor], this.spliceCount, this.maxTicksLimitX))
            this.colors.splice(indexColor,1)
            this.gradients.splice(indexColor, 1)
          }

          let foundCurve=this.char.datasets.find((item)=>item.label==curve.name)
          foundCurve.push(curve.y)

        })
      },
      setGradient() {
        var childrenRef = this.$children[0].$refs;
        this.gradient1 = childrenRef.canvas.getContext("2d")
        .createLinearGradient(0, 0, 0, 450);
        this.gradient2 =childrenRef.canvas.getContext("2d")
        .createLinearGradient(0, 0, 0, 450);

        this.gradient1.addColorStop(0, "rgba(255, 0,0, 0.5)");
        this.gradient1.addColorStop(0.5, "rgba(255, 0, 0, 0.25)");
        this.gradient1.addColorStop(1, "rgba(255, 0, 0, 0)");

        this.gradient2.addColorStop(0, "rgba(27, 108, 208, 0.9)");
        this.gradient2.addColorStop(0.5, "rgba(27, 108, 208, 0.25)");
        this.gradient2.addColorStop(1, "rgba(27, 108, 208, 0)");


        this.gradients = [this.gradient1, this.gradient2];
      },

      updateAfterSleep() {
        var lastTime = (new Date()).getTime(), me = this;

        setInterval(function() {
          var currentTime = (new Date()).getTime();
          if (currentTime > (lastTime + 2000*2)) {  // ignore small delays
            me.$router.go(0);
          }
          lastTime = currentTime;
        }, 2000);
      }
    },
    mounted: function() {
      this.setGradient()
    }
  }
</script>

<style lang="stylus" scoped>
  .tpl_chart
    width: 49%!important
    max-width 51%!important
    padding 24px!important
    background: #fff;
    border-radius: 12px;
    padding-bottom 12px!important

  .tpl__header
      margin-bottom: 20px;
  .line-chart
    background white
    width 100%
    height 300px
    font-weight: 600;
    font-size: 12px;

  .tpl-info__charts_mobile
    .tpl_chart
      max-width 100%!important
      width 100%!important
</style>
