import Vue from 'vue'
import InputSelect from './input/InputSelect.vue'
import ProjectInput from './project/ProjectInput.vue'
import BaseBtn from './BaseBtn.vue'
import BaseInput from './input/BaseInput.vue'
import SvgIcon from './SvgIcon.vue'
import BaseMenu from './menu/BaseMenu.vue'
import BaseSnackbar from './BaseSnackbar.vue'
import BaseDialog from './BaseDialog.vue'
import BaseEditor from './BaseEditor.vue'
import BaseDropdown from './BaseDropdown.vue'
import BaseSwitch from './input/BaseSwitch.vue'
import BaseSpiner from './BaseSpiner.vue'
import BaseFile from './input/BaseFile.vue'

Vue.component('svg-icon',SvgIcon)
Vue.component('base-btn',BaseBtn)
Vue.component('base-menu',BaseMenu)
Vue.component('base-snackbar',BaseSnackbar)
Vue.component('base-input',BaseInput)
Vue.component("input-select", InputSelect);
Vue.component("project-input", ProjectInput);
Vue.component("base-dialog", BaseDialog);
Vue.component("base-editor", BaseEditor);
Vue.component("base-dropdown", BaseDropdown);
Vue.component("base-switch", BaseSwitch);
Vue.component("base-spiner", BaseSpiner);
Vue.component("base-file", BaseFile);